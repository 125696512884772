<script setup lang="ts">
  import { computed } from 'vue'
  import { useQueryUsers, getUserById } from '@/api/useUsers'
  import { useUser } from '../composable/useUser'

  const props = withDefaults(
    defineProps<{
      userId: number | null
      includeDescendantCompanies?: boolean
    }>(),
    {
      includeDescendantCompanies: false,
    }
  )

  const includeDescendantCompanies = computed(
    () => props.includeDescendantCompanies
  )

  const { data: users } = useQueryUsers({
    includeCurrentCompany: true,
    includeAncestors: true,
    includeDeleted: true,
    includeDescendantCompanies: includeDescendantCompanies.value,
  })

  const userDetails = computed(() => {
    if (!props.userId || !users.value) {
      return
    }
    return getUserById(users.value, props.userId)
  })
  const { getUserInitials, getUserFullNameSafe } = useUser()
</script>

<template>
  <div
    v-tooltip="getUserFullNameSafe(userDetails)"
    class="bg-gray-var-300 flex aspect-square items-center justify-center rounded-full"
    data-testid="base-user-avatar"
  >
    <p v-if="userDetails" class="text-gray-var-900 uppercase">
      {{ getUserInitials(userDetails) }}
    </p>
  </div>
</template>
