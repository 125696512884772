import { useI18n } from 'vue-i18n'
import { User } from '@/api/useUsers.types'
import {
  getUserFullName,
  getUserFullNameSafe,
  getUserInitials,
} from '@/api/utils/string'

export const useUser = () => {
  const { t } = useI18n()

  return {
    getUserFullName,
    getUserFullNameSafe: (user: User | undefined) =>
      getUserFullNameSafe(user, t),
    getUserInitials,
  }
}
